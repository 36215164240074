import styled from '@emotion/styled'
import { Box } from '@mui/material'
import type {
  ClaimsContactInformation,
  ContractClaimsContactInformations,
} from '@orus.eu/backend/src/services/claim-contact-service'
import {
  ContentContainerAppClient,
  PageTitle,
  spacing,
  Text,
  useScreenType,
  useTranslate,
  type AvatarDecorativeProps,
} from '@orus.eu/pharaoh'
import { ClaimCard } from '@orus.eu/pharaoh/src/components/features/claim-card'
import { memo } from 'react'
import { trpcReact } from '../../client'
import { SmallScreenMargin } from '../templates/small-screen-margin'

const ReportClaimPage = memo(function ReportClaimPage() {
  const translate = useTranslate()
  const [contractsClaimsContactInformations] = trpcReact.claims.getClaimsContactInfo.useSuspenseQuery()
  const screenType = useScreenType()

  return (
    <>
      <PageTitle title={translate('report_claim')} />
      <ContentContainerAppClient>
        {screenType === 'desktop' ? (
          <ContractsClaims contractsClaimsContactInformations={contractsClaimsContactInformations} />
        ) : (
          <SmallScreenMargin>
            <ContractsClaims contractsClaimsContactInformations={contractsClaimsContactInformations} />
          </SmallScreenMargin>
        )}
      </ContentContainerAppClient>
    </>
  )
})

export const ContractsClaims = memo<{
  contractsClaimsContactInformations: ContractClaimsContactInformations[]
}>(function ContractsClaims({ contractsClaimsContactInformations }) {
  const screenType = useScreenType()
  return (
    <>
      {contractsClaimsContactInformations.map((contractClaimsContactInformations, index) => (
        <Box key={index}>
          <Text variant={'subtitle2'}>{contractClaimsContactInformations.activity?.displayName}</Text>
          <Text variant={'body2'}>{contractClaimsContactInformations.address}</Text>
          <CardsContainer isLargeScreen={screenType === 'desktop'}>
            {contractClaimsContactInformations.claimsContactInformations?.map((claimContact, index) => (
              <ClaimCard
                key={claimContact.type + index}
                title={claimContact.title}
                phoneNumber={claimContact.phoneNumber}
                email={claimContact.email}
                description={claimContact.description}
                avatar={avatars[claimContact.type].avatar}
                avatarBackgroundColor={avatars[claimContact.type].avatarBackgroundColor}
              />
            ))}
          </CardsContainer>
        </Box>
      ))}
    </>
  )
})

const CardsContainer = styled(Box)<{ isLargeScreen: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.isLargeScreen ? 'repeat(2, 1fr)' : '1fr')};
  gap: ${(props) => (props.isLargeScreen ? spacing[50] : spacing[60])};
  padding: ${spacing[50]} 0;
`

const avatars: {
  [k in ClaimsContactInformation['type']]: {
    avatar: AvatarDecorativeProps['icon']
    avatarBackgroundColor: AvatarDecorativeProps['backgroundColor']
  }
} = {
  decennialLiability: { avatar: 'shovel-light', avatarBackgroundColor: 'periwinkle' },
  healthCoverage: { avatar: 'stethoscope-light', avatarBackgroundColor: 'peach' },
  publicLiability: { avatar: 'book-open-cover-light', avatarBackgroundColor: 'sky' },
  legalProtection: { avatar: 'scale-balanced-light', avatarBackgroundColor: 'peach' },
  assistance: { avatar: 'bell-concierge-light', avatarBackgroundColor: 'mindaro' },
  multiriskCoverage: { avatar: 'warehouse-light', avatarBackgroundColor: 'jasmine' },
}

export default ReportClaimPage
